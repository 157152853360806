import React, { useState, useEffect } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Todo from "./pages/Todo";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import ClientManagement from "./pages/ClientManagement";
import InteriorDesignerManagement from "./pages/InteriorDesignerManagement";
import ClientProfile from "./pages/ClientProfile";
import InteriorDesignerProfile from "./pages/InteriorDesignerProfile";
import ProjectDetails from "./pages/ProjectDetails";
import VirtualConsultant from "./pages/VirtualConsultant";
import ProjectInquiry from "./pages/ProjectInquiry";
import ApproveInquiry from "./pages/ApproveInquiry";
import ConsultantPayments from "./pages/ConsultantPayments";
import Subscription from "./pages/Subscription";
import Request from "./pages/Request";
import Integrations from "./pages/Integrations";
import Coupons from "./pages/Coupons";

// Animation
import AOS from "aos";
import "aos/dist/aos.css";
import Consultant from "./pages/Consultant";
import Metadescriptions from "./pages/Metadescriptions";
import Reviews from "./pages/Reviews";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

    /// users
    // { url: "requests", component: Requests },

    /// client
    { url: "client-management", component: ClientManagement },
    { url: "client-profile", component: ClientProfile },
    { url: "project-details", component: ProjectDetails },
    /// interior designer
    {
      url: "interior-designer-management",
      component: InteriorDesignerManagement,
    },
    {
      url: "interior-designer-management/:id/consultation",
      component: Consultant,
    },
    {
      url: "interior-designer-management/:id",
      component: InteriorDesignerProfile,
    },

    ///
    { url: "virtual-consultant", component: VirtualConsultant },
    { url: "inquiry-management", component: ProjectInquiry },
    { url: "inquiry-approval-management", component: ApproveInquiry },

    { url: "consultant-payments", component: ConsultantPayments },
    { url: "subscription", component: Subscription },
    { url: "request", component: Request },
    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    { url: "todo", component: Todo },
    //integrations
    { url: "integrations", component: Integrations },
    { url: "coupons", component: Coupons },
    { url: "metadescriptions", component: Metadescriptions },
    { url: "reviews", component: Reviews },
    {},
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
