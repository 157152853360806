import instance from "../Instance";
import {
  GET_REVIEWS,
  CREATE_REVIEW,
  UPDATE_REVIEW,
  DELETE_REVIEW,
} from "./ApiEndpoints";

export function getReviews(currentPage, limit, search = "") {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.get(
    `${GET_REVIEWS}?page=${currentPage}&limit=${limit}&search=${search}`,
    {
      headers: myHeaders,
    }
  );
}

export function createReview(reviewData) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(CREATE_REVIEW, reviewData, {
    headers: myHeaders,
  });
}

export function updateReview(reviewId, updateData, source) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.put(
    UPDATE_REVIEW,
    { reviewId, source, ...updateData },
    {
      headers: myHeaders,
    }
  );
}


export function deleteReview(reviewId, source) {
  const data = localStorage.getItem("taldDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.put(
    DELETE_REVIEW,
    { reviewId, source },
    {
      headers: myHeaders,
    }
  );
}
