import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  updateReview,
  createReview,
} from "../../services/Reviews/ReviewsService";
import { toast } from "react-toastify";
import { FaStar } from "react-icons/fa";

export default function AddReview({ show, onHide, table, review }) {
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [designerEmail, setDesignerEmail] = useState("");
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const isSubmittingRef = useRef(false);

  useEffect(() => {
    if (review) {
      setRating(review.rating || 0);
      const text = review.reviewText || review.comment || "";
      setReviewText(text);
      setCharCount(text.length);
      setDesignerEmail(review.designer?.email || "");
  
      if (review.source === "Review") {
        setName(review.name || ""); 
      } else if (review.source === "Consultation") {
        setName(review.designer?.name || ""); 
      } else {
        setName(""); 
      }
    } else {
      setRating(0);
      setReviewText("");
      setDesignerEmail("");
      setName("");
      setCharCount(0);
    }
  }, [review]);
  

  function handleSave() {
    if (isSubmittingRef.current) return;
    isSubmittingRef.current = true;
  
    if (!review && !designerEmail) {
      toast.error("Please enter a designer email.");
      isSubmittingRef.current = false;
      return;
    }
    if (reviewText.length === 0) {
      toast.error("Please enter a review text.");
      isSubmittingRef.current = false;
      return;
    }
    if (rating < 1) {
      toast.error(
        "Please select a rating for the review. Ratings must be between 1 and 5."
      );
      isSubmittingRef.current = false;
      return;
    }
    if (reviewText.length > 500) {
      toast.error("The review text must be less than 500 characters long.");
      isSubmittingRef.current = false;
      return;
    }
    if (!name) {
      toast.error("Please enter a name for the review.");
      isSubmittingRef.current = false;
      return;
    }

    const source = review ? review.source : "Review";
  
    const reviewData = {
      name,
      rating,
    };
    
    if (source === "Review") {
      reviewData.reviewText = reviewText;
    } else if (source === "Consultation") {
      reviewData.comment = reviewText;
    }
    
    if (!review) {
      reviewData.designerEmail = designerEmail;
      reviewData.source = source;
    }
    
    const request = review && review._id
      ? updateReview(review._id, reviewData, source)
      : createReview(reviewData);
  
    request
      .then(() => {
        toast.success(review ? "Review updated successfully" : "Review created successfully");
        onHide();
        table();
      })
      .catch(() => {
        toast.error("Error saving review");
      })
      .finally(() => {
        isSubmittingRef.current = false;
      });
  }
  

  const StarRatingComponent = () => (
    <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
      {[1, 2, 3, 4, 5].map((star) => (
        <FaStar
          key={star}
          size={30}
          color={star <= rating ? "#ffc107" : "#e4e5e9"}
          onClick={() => setRating(star)}
          style={{ cursor: "pointer" }}
        />
      ))}
    </div>
  );

  const handleReviewTextChange = (e) => {
    const text = e.target.value;
    if (text.length <= 500) {
      setReviewText(text);
      setCharCount(text.length);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{review ? "Edit Review" : "Add New Review"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!review && (
          <Form.Group controlId="formDesignerEmail">
            <Form.Label>Designer Email</Form.Label>
            <Form.Control
              type="email"
              value={designerEmail}
              onChange={(e) => setDesignerEmail(e.target.value)}
            />
          </Form.Group>
        )}
        {(!review || review?.source === "Review") && (
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        )}
        <Form.Group controlId="formRating">
          <Form.Label>Rating</Form.Label>
          <StarRatingComponent />
        </Form.Group>
        <Form.Group controlId="formReviewText">
          <Form.Label>Review Text</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={reviewText}
            onChange={handleReviewTextChange}
            maxLength={500}
          />
          <Form.Text className="text-muted">
            {charCount}/500 characters
          </Form.Text>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={isSubmitting}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isSubmitting}>
          {review ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
