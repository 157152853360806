import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, Table, Modal, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/common/Spinner";
import {
  getReviews,
  deleteReview,
} from "../../services/Reviews/ReviewsService";
import Pagination from "../components/common/Pagination";
import { toast, ToastContainer } from "react-toastify";
import "./interiorDesignerManagement.css";
import AddReview from "./AddReview";
import StarRating from "../components/common/StarRating";
import { FaPlus } from "react-icons/fa";

export default function Reviews(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const notifyError = (error) => {
    toast.error(`❌ ${error}`, {
      position: "top-right",
      autoClose: 5000,
    });
  };

  const [loader, setLoader] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const limit = 30;
  const [listLength, setListLength] = useState(0);
  const [editModal, setEditModal] = useState({ show: false, review: null });
  const [deleteModal, setDeleteModal] = useState({ show: false, review: null });

  function getTableData() {
    setLoader(true);
    getReviews(currentPage, limit, debouncedSearch)
      .then((response) => {
        setReviews(response.data.data.reviews);
        const total = response.data.data.total;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        setListLength(total);
      })
      .catch((error) => {
        console.log(error.response);
        notifyError("Error fetching reviews");
        setLoader(false);
      });
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search.trim());
      setCurrentPage(0);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debouncedSearch]);

  function confirmDelete(review) {
    setDeleteModal({ show: true, review });
  }

  function handleDelete() {
    if (deleteModal.review) {
      setLoader(true);
      deleteReview(deleteModal.review._id, deleteModal.review.source)
        .then(() => {
          toast.success("Review deleted successfully");
          getTableData();
          setDeleteModal({ show: false, review: null });
        })
        .catch((error) => {
          console.log(error.response);
          notifyError("Error deleting review");
          setLoader(false);
        });
    }
  }

  return (
    <div>
      <ToastContainer />
      <PageTitle activeMenu="Reviews" motherMenu="Content Management" />
      <Col>
        <Card>
          <Card.Header className="">
            <div className="row d-flex justify-content-end">
              <div className="col-4" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-default">
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                className="btn btn-primary shadow sharp mr-2 my-auto"
                onClick={() => setEditModal({ show: true, review: null })}
              >
                <FaPlus />
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>USER</strong>
                    </th>
                    <th>
                      <strong>DESIGNER</strong>
                    </th>
                    <th>
                      <strong>RATING</strong>
                    </th>
                    <th>
                      <strong>COMMENT</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reviews?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.user?.email || item.name}</td>
                      <td>{item.designer?.email}</td>
                      <td>
                        <StarRating rating={item.rating} />
                      </td>
                      <td>
                        {item.comment || item.reviewText ? (
                          <>
                            {(item.comment || item.reviewText).substring(0, 30)}{" "}
                            {(item.comment || item.reviewText).length > 30
                              ? "..."
                              : ""}
                          </>
                        ) : (
                          "No comment available"
                        )}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary light"
                            className="light sharp btn btn-secondary"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                setEditModal({ show: true, review: item })
                              }
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => confirmDelete(item)}>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {reviews?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5">
                Sorry, Data Not Found!
              </div>
            ) : null}
            {pageCount > 1 && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {Math.min((currentPage + 1) * limit, listLength)} of{" "}
                  {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      <AddReview
        show={editModal.show}
        review={editModal.review}
        table={getTableData}
        onHide={() => setEditModal({ show: false, review: null })}
      />
      <Modal
        show={deleteModal.show}
        onHide={() => setDeleteModal({ show: false, review: null })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this review?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDeleteModal({ show: false, review: null })}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {loader && <Spinner />}
    </div>
  );
}
