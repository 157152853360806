import React from "react";

export default function StarRating({ rating, maxStars = 5 }) {
  const fullStar = "★";
  const emptyStar = "☆";

  return (
    <div>
      {[...Array(maxStars)].map((_, index) => (
        <span style={{ color: "#f39c12", fontSize: "20px" }} key={index}>
          {index < rating ? fullStar : emptyStar}
        </span>
      ))}
    </div>
  );
}
